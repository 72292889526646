import { MAX_INTERVAL_DAYS } from '@/utils';

import ErrorCodes from './error-codes';

export default {
  [ErrorCodes.CannotCreateFromTemplate]: 'Template format is invalid.',
  [ErrorCodes.CompanyNotFound]: 'Company not found.',
  [ErrorCodes.DuplicateFilename]:
    'The file name you are attempting to add is already attached to this project. Please try again with a different file name.',
  [ErrorCodes.EmailTaken]: 'Email address already in use.',
  [ErrorCodes.FailedLogin]: 'Your credentials are not correct.',
  [ErrorCodes.InvalidGroups]:
    'You are not in the correct team to access this Application.',
  [ErrorCodes.InvalidUser]: 'We could not find any user with that email.',
  [ErrorCodes.NoCSVFile]:
    'Only CSV files are accepted. This may just require adding a .csv to the filename.',
  [ErrorCodes.NotAllowedToRenameFilesWithSameName]:
    'Cannot rename the file with the same name.',
  [ErrorCodes.NotSmsFactor]:
    'You do not have MFA activated, please contact your administrator.',
  [ErrorCodes.ProjectNotFound]: 'The project was not found.',
  [ErrorCodes.ReassignmentOfMultipleMainRolesNotAllowed]:
    "A lead role cannot be assigned to another user that already has a lead role. Please reassign the other user's role to a non-lead role first.",
  [ErrorCodes.TaskListRequiredFieldMissingTitle]:
    'Required field missing - Title.',
  [ErrorCodes.UserCanNotReAssignTask]: 'This task assignation is not allowed.',
  [ErrorCodes.UserCanNotUpdateProject]:
    'You are not allowed to update this project.',
  [ErrorCodes.UserNotInProject]: 'User not in project.',
  [ErrorCodes.UserHasNotTasksToBeReassigned]:
    'There are not tasks to be reassigned.',
  [ErrorCodes.UserHasPmOrLoadRole]:
    'Action not allowed because the user you are trying to select is already a Lead or PM.',
  [ErrorCodes.UserIsInactive]:
    'Is not allowed to add the user to the project because the user is inactive.',
  [ErrorCodes.TaskTrackerNotFound]:
    'No templates found for the chosen practice.',
  [ErrorCodes.TaskTrackerTaskNotFound]: 'No tasks found for chosen template.',
  [ErrorCodes.TaskTrackerInWrongStatus]: 'Invalid status.',
  [ErrorCodes.InvalidPractice]: 'Invalid practice.',
  [ErrorCodes.UserNotFound]: 'User not found.',
  [ErrorCodes.ProjectAlreadyExist]:
    'Project title already exists - project titles must be unique.',
  [ErrorCodes.SameProjectLeadAndManager]:
    'Project users can have only one role per project. Please select different users for the Project Manager and Lead Consultant roles.',
  [ErrorCodes.NoTimezone]: 'Timezone expected.',
  [ErrorCodes.FileAlreadyAttachedToTask]: 'File is already attached to task.',
  [ErrorCodes.OnlyTevoraInterviewersAllowed]:
    'Only Tevora staff are allowed to be interviewers.',
  [ErrorCodes.UnableToStartGraphClient]:
    'Unable to start Microsoft Graph client.',
  [ErrorCodes.GraphUserNotFound]:
    'User is not found in Microsoft Graph client. Be sure that the user is in the same tenant as the application.',
  [ErrorCodes.CantScheduleMeeting]:
    'An error has occurred. The meeting could not be scheduled',

  [ErrorCodes.TaskReferenceIdAlreadyExistOnProject]:
    'Task IDs must be unique or null',

  [ErrorCodes.ReassignmentOfNoConsultantRolesNotAllowed]: `A lead consultant role cannot be assigned to another user who does not have a consultant role. Please reassign the other user's consultant.`,

  [ErrorCodes.LeadUserNotAllowedToBeRemovedException]: `To remove this role you must reassign it to another user. Please reassign the role first then you will be able to remove the user from the project.`,

  [ErrorCodes.ClientLeadMissingInProject]: `Client Lead Role must be set to activate the project`,
  [ErrorCodes.ForbiddenRoleAssignment]: `Your current role does not allow you to assign this role, if you believe this is an error please contact your administrator.`,

  [ErrorCodes.ForbiddenRoleAssignmentForTemplates]:
    'Invalid target role for project templates, if you believe this is an error please contact your administrator.',

  [ErrorCodes.ForbiddenRoleAssignmentForLead]:
    'Can not assign Lead role to a lead role, if you believe this is an error please contact your administrator.',

  [ErrorCodes.LeadConsultantMissingInProject]:
    'Lead Consultant Role not found in project.',

  [ErrorCodes.FolderProjectHasOpenTasks]:
    'Switching Project Types can only be done if all tasks are in OPEN or INCOMPLETE status.',

  [ErrorCodes.NoExcelFile]:
    'Only Excel files are accepted. This may just require adding a .xlsx to the filename.',

  [ErrorCodes.InterviewDateTimeIsInThePast]:
    'Can not schedule meeting, invalid date time.',

  [ErrorCodes.FailToScheduleInterview]:
    'Something went wrong scheduling the interview, if the problem persists please contact your administrator.',

  [ErrorCodes.FailToCreateSubscription]:
    'Something went wrong, if the problem persists please contact your administrator.',

  [ErrorCodes.InvalidMeetingOrganizer]:
    'Invalid Meeting organizer. Only Lead Consultants and Consultants are allowed.',

  [ErrorCodes.InvalidMeetingArgument]:
    'Could not schedule interview, missing required information.',

  [ErrorCodes.PracticesCanNotBeChangedWhenProjectHasTasks]:
    'Cannot remove practice from project, this project has tasks.',

  [ErrorCodes.InterviewDayIsInvalid]:
    'Interview day is invalid. Make sure the day is between Monday and Friday.',

  [ErrorCodes.MaxIntervalExceeded]: `The requested time duration specified for interview is too long. The allowed limit is ${MAX_INTERVAL_DAYS} days.`,
  [ErrorCodes.UserCannotUpdateCompany]: 'User can not update company.',
  [ErrorCodes.ClientCannotAddUserToProject]:
    'Client role cannot add users to a project. The role must be Client Lead.',

  [ErrorCodes.RootFolderAlreadyExist]: 'Root folder already exist.',
  [ErrorCodes.RootFolderCannotBeDeleted]: 'Root folder cannot be deleted.',
  [ErrorCodes.FolderNotFound]: 'Folder not found.',
  [ErrorCodes.FolderCannotBeMovedToItself]: 'Folder cannot be moved to itself.',
  [ErrorCodes.FolderAlreadyExist]: 'Folder already exist.',
  [ErrorCodes.FolderFileNotFound]: 'File not found.',
  [ErrorCodes.ForbiddenFolderAction]: 'You are not allowed to perform this action.',
  [ErrorCodes.FolderFileAlreadyExist]: 'File already exist. please rename the file and try again.',
  [ErrorCodes.FolderNotSupported]: 'Folder not supported.',
  [ErrorCodes.TaskNotSupported]: 'Task not supported.',
} as Record<ErrorCodes, string>;
